<template>
  <div class="messagewall common-bg">
    <div class="container-lg position-relative py-lg-5">
      <div class="d-flex btn-nav justify-content-between my-5">
        <router-link
          to="/lobby"
          class="btn btn-outline-light btn-sm text-uppercase"
          >Back to Lobby</router-link
        >
        <button
          class="btn btn-primary btn-sm text-uppercase"
          data-toggle="modal"
          data-target="#writeMessageModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path
              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
            />
            <path
              fill-rule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            />
          </svg>
          Write Message
        </button>
      </div>
      <div class="row">
        <div class="col-8 mx-auto">
          <div class="msg-card card card-body border-primary shadow">
            <blockquote class="blockquote">
              <h5 class="mb-1">
                While sharing your experience, please note the following:
              </h5>
              <ul style="font-size: 0.9rem">
                <li>
                  JPMorgan Chase supports the constructive exchange of differing
                  views.
                </li>
                <li>
                  Please make sure comment abides by our
                  <a
                    href="http://sites.jpmchase.com/sites/codeofconduct/Pages/CodeofConduct.aspx"
                    class="text-info text-decoration-none"
                    target="_blank"
                    ><u>Code of Conduct</u></a
                  >
                  and company policy.
                </li>
                <li>
                  The Company reserves the right to remove any comment for any
                  reason or disable the comment feature at any time.
                </li>
                <li>
                  Your name will be associated with your comment and your
                  comment will be seen by others.
                </li>
                <li>Your comment should be relative to the subject matter.</li>
                <li>
                  Comments should not contain personal information about a
                  family member's medical situation to maintain privacy and
                  abide by the U.S. Genetic Information Nondiscrimination Act
                  (<a
                    href="http://sites.jpmchase.com/sites/companyhome/About%20Us/Diversity/ODI/Pages/TheUSGeneticInformationNondiscriminationActof2008GINA.aspx"
                    class="text-info text-decoration-none"
                    target="_blank"
                    ><u>GINA</u></a
                  >).
                </li>
              </ul>
            </blockquote>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div
          class="col-sm-4 mb-3"
          v-for="(message, index) in messages"
          :key="index"
        >
          <div class="msg-card card card-body border-primary shadow">
            <blockquote class="blockquote">
              <p class="mb-0">
                {{ message.message }}
              </p>
              <footer class="blockquote-footer float-right">
                <cite>{{ message.author }}</cite>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
    <!-- write message modal -->
    <div
      class="modal fade"
      id="writeMessageModal"
      tabindex="-1"
      aria-labelledby="writeMessageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>

            <h3 class="font-weight-bold mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-chat-left-quote"
                viewBox="0 0 16 16"
              >
                <path
                  d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                />
                <path
                  d="M7.066 4.76A1.665 1.665 0 0 0 4 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z"
                />
              </svg>
              Message
            </h3>
            <small class="form-text text-body mb-4">
              Please type your message below
            </small>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Name"
                v-model="name"
              />
            </div>
            <div class="form-group mb-5">
              <textarea
                name="message"
                v-model="message"
                id="message"
                rows="5"
                class="form-control"
                placeholder="Type here..."
                maxlength="200"
              ></textarea>
              <small class="float-right">
                <span>{{ 200 - message.length }}</span>
                characters left
              </small>
            </div>

            <button
              class="btn btn-primary btn-block"
              :disabled="!message.length"
              @click="storeMessage"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import util from "@/assets/js/util";
import Api from "@/assets/js/Api";
import $ from "jquery";
export default {
  name: "messagewall",
  data() {
    return {
      messages: [],
      character_limit: "",
      message: "",
      name: localStorage.getItem("participant_name"),
    };
  },
  methods: {
    storeMessage() {
      $("#writeMessageModal").modal("toggle");
      if (this.name) localStorage.setItem("participant_name", this.name);

      Api.post("wall-message", { message: this.message, name: this.name }).then(
        (res) => {
          this.message = "";
          this.messages = res.data.messages;
        }
      );
    },
    getAllMessages() {
      Api.post("wall-messages").then((res) => {
        this.messages = res.data.messages;
      });
    },
    charCount() {
      var textarea = document.querySelector("textarea");

      textarea.addEventListener("input", function () {
        var maxlength = this.getAttribute("maxlength");
        var currentLength = this.value.length;

        if (currentLength >= maxlength) {
          console.log("You have reached the maximum number of characters.");
        } else {
          this.character_limit = maxlength - currentLength;
          console.log(this.character_limit);
        }
      });
    },
  },
  mounted() {
    this.getAllMessages();
  },
};
</script>

<style lang="scss" scoped>
.messagewall {
  & .msg-card {
    cursor: pointer;
    border-width: 5px;

    p {
      font-size: 14px;
    }
    .blockquote-footer {
      font-size: 14px;
    }
  }
}
</style>
